import React from "react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import EditStu from "./EditStu"
import useAuth from "./Hooks/useAuth"
export default function Students({ data }) {
  const { user } = useAuth()
  return (
    <div className='card bg-light'>
      <div className='card-body'>
        <table class='table table-striped table-sm'>
          <thead>
            <tr>
              <th scope='col'>Name</th>
              <th scope='col'>School</th>
              <th scope='col'>Class</th>
              <th scope='col'>Email</th>
              <th scope='col'>Mobile No.</th>
              <th scope='col'>State</th>
              <th scope='col'>City</th>
              <th scope='col'>Action</th>
              <th scope='col'>View Report</th>
            </tr>
          </thead>
          <tbody>
            {(() => {
              if (data.length === 0) {
                return <>No data Available</>
              } else {
                return (
                  <>
                    {data.map((el, idx) => {
                      return (
                        <>
                          <tr>
                            <td>{el.name}</td>
                            <td>{el.school}</td>
                            <td>{el.Class}</td>
                            <td>{el.email}</td>
                            <td>{el.mobile}</td>
                            <td>{el.state}</td>
                            <td>{el.city}</td>
                            <td>
                              <button
                                className='mx-1 btn btn-sm btn-primary'
                                data-mdb-toggle='modal'
                                data-mdb-target={`#exampleModal-${el._id}`}
                              >
                                Edit
                              </button>
                              <EditStu
                                data={el}
                                id={`exampleModal-${el._id}`}
                              />
                              <button
                                className='btn btn-sm btn-danger'
                                onClick={async () => {
                                  try {
                                    await fetch(
                                      "/api/admin/dashboard/students",
                                      {
                                        method: "DELETE",
                                        body: JSON.stringify({ _id: el._id }),
                                        headers: {
                                          Accept: "appllication/json",
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${user.token}`,
                                        },
                                        // used when we want to share cookies or tokes to backend
                                        credentials: "include",
                                      }
                                    ).then((response) => response.json())
                                    window.alert("USER DELETED")
                                  } catch (err) {
                                    window.alert("Unsuccessful Operation")
                                  }
                                }}
                              >
                                Delete
                              </button>
                            </td>
                            <td>
                              <Link
                                to='/report'
                                state={{ res: el }}
                                className='btn btn-sm btn-tertiary'
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </>
                )
              }
            })()}
          </tbody>
        </table>
      </div>
    </div>
  )
}
