import React from 'react'
import { useLocation } from 'react-router-dom'
import Result1 from './Result/Result1'
import Result2 from './Result/Result2'
import Result3 from './Result/Result3'
import Result4 from './Result/Result4'
import Result5 from './Result/Result5'
import Result6 from './Result/Result6'
import Result7 from './Result/Result7'
import Info4 from './Result/Info4'
import { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import Info2 from './Result/Info2'
export default function Report() {
  const location = useLocation()
  const { res } = location.state
  console.log('location.state', location.state)
  const ut = res.tests
  console.log(ut)
  const printRef = useRef()
  //alert(JSON.stringify(res))
  return (
    <div className='card bg-light m-5'>
      <div className='card-body'>
        <div ref={printRef} id='download'>
          <h3 className='text-info'>Student Details</h3>
          <hr />
          <h5>
            <span className='text-info'>Name: </span>
            {res.name}
          </h5>
          <h5>
            <span className='text-info'>Class: </span>
            {res.Class}
          </h5>
          <h5>
            <span className='text-info'>School:</span>
            {res.school}
          </h5>
          <br />
          <div>
            {(() => {
              if (ut) {
                if (ut.test1.attempt === true) {
                  return <Result1 qn={ut.test1.traits} userData={res} />
                }
              }
            })()}
          </div>
          <div>
            {(() => {
              if (ut) {
                if (ut.test2.attempt === true) {
                  return <Result2 qn={ut.test2.traits} userData={res} />
                }
              }
            })()}
          </div>
          <div>
            {(() => {
              if (ut) {
                if (ut.test3.attempt === true) {
                  return <Result3 qn={ut.test3.traits} userData={res} />
                }
              }
            })()}
          </div>
          <div>
            {(() => {
              if (ut) {
                if (ut.test4.attempt === true) {
                  return <Result4 qn={ut.test4.traits} userData={res} />
                }
              }
            })()}
          </div>
          <div>
            {(() => {
              if (ut) {
                if (ut.test5.attempt === true) {
                  return <Result5 qn={ut.test5.traits} userData={res} />
                }
              }
            })()}
          </div>
          <div>
            {(() => {
              if (ut) {
                if (ut.test6.attempt === true) {
                  return <Result6 qn={ut.test6.traits} userData={res} />
                }
              }
            })()}
          </div>
          <div>
            {(() => {
              if (ut) {
                if (ut.test7.attempt === true) {
                  return <Result7 qn={ut.test7.traits} userData={res} />
                }
              }
            })()}
          </div>
          <div className='text-center'>
            Mentorify Technologies Private Limited
          </div>
        </div>

        <ReactToPrint
          trigger={() => (
            <button
              type='button'
              className='btn btn-info btn-block mt-3'
              style={
                {
                  // width:'85%'
                }
              }
            >
              <i className='fa fa-download'></i> Download report as PDF
            </button>
          )}
          content={() => document.getElementById('download')}
          documentTitle='Career Clarify Test Result'
        />
      </div>
    </div>
  )
}
