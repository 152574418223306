import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "../Hooks/useAuth"

const Logout = () => {
  const { dispatch } = useAuth()
  const navigate = useNavigate()
  const callLogoutPage = () => {
    dispatch({ type: "LOGOUT" })
    try {
      navigate("/login", { replace: true })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    callLogoutPage()
  })
  return <div>Logout Page</div>
}

export default Logout
