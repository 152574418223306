import React, { useState } from "react"
import "./Login.css"
import { useNavigate } from "react-router-dom"
import useAuth from "../Hooks/useAuth"
const Login = () => {
  const { dispatch } = useAuth()

  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const loginUser = async (e) => {
    e.preventDefault()
    try {
      const res = await fetch("/api/admin/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      })
      const data = await res.json()
      if (!data || !res.ok) {
        window.alert("INVALID CREDENTIALS !")
      } else {
        window.alert("ADMIN LOGIN SUCCESSFULL !")
        dispatch({ type: "LOGIN", payload: data })
        localStorage.setItem("user", JSON.stringify(data))
        console.log("hi", data)
        navigate("/dashboard")
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <section className='login-section'>
        <form method='POST' className='login-modal '>
          <div className='login-header'>
            <h2>
              Admin <br /> Login Account
            </h2>
          </div>
          <div className='credentials'>
            <p>
              Email Address :
              <br />
              <input
                className='input'
                type='email'
                name='email'
                id='email'
                placeholder='Fill in your email address'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </p>
            <p>
              Password :
              <br />
              <input
                className='input'
                type='password'
                name='Password'
                id='pass'
                placeholder='Input your password'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </p>
          </div>
          <input
            className='Login'
            type='submit'
            value='Log-In'
            onClick={loginUser}
          />
          <a
            as='button'
            class='nav-link Login'
            href='#'
            data-mdb-toggle='modal'
            data-mdb-target='#exampleModal'
          >
            Forgot Password
          </a>
        </form>

        {/* Modal */}
        <div
          class='modal fade'
          id='exampleModal'
          tabindex='-1'
          aria-labelledby='exampleModalLabel'
          aria-hidden='true'
        >
          <div class='modal-dialog'>
            <div class='modal-content'>
              <div class='modal-header'>
                <h5 class='modal-title' id='exampleModalLabel'>
                  Contact us
                </h5>
                <button
                  type='button'
                  class='btn-close'
                  data-mdb-dismiss='modal'
                  aria-label='Close'
                ></button>
              </div>
              <div style={{ paddingLeft: "25px" }} class='modal-body'>
                <h6 style={{ marginBottom: "25px" }}>
                  <span class='key'>
                    <i class='fa-solid fa-phone'></i>&nbsp; Call:
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp; +91 639-450-6912
                </h6>
                <h6>
                  <span class='key'>
                    <i class='fa-solid fa-envelope'></i>&nbsp; Email:
                  </span>
                  &nbsp;&nbsp;&nbsp;&nbsp; franchise@careerclarify.com
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login
