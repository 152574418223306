import React from "react"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { State, City } from "country-state-city"
import "./Alltest.css"
import Students from "./Students"
import useAuth from "./Hooks/useAuth"

export default function Alltests() {
  const { user } = useAuth()

  const navigate = useNavigate()
  const [userData, setUserData] = useState({})
  const [image, setImage] = useState(null)
  const [allstu, setallstu] = useState([])
  const [classNames, setClassNames] = useState([])
  const [schoolNames, setSchoolNames] = useState([])
  const [stateNames, setStateNames] = useState([])
  const [cityNames, setCityNames] = useState([])

  const [selectedClass, setSelectedClass] = useState("*")
  const [selectedSchool, setSelectedSchool] = useState("*")
  const [selectedState, setSelectedState] = useState("*")
  const [selectedCity, setSelectedCity] = useState("*")
  const [searchBox, setSearchBox] = useState("")

  const [dynamicArr, setDynamicArray] = useState([])

  const countryCode = "IN"
  const stateArr = State.getStatesOfCountry(countryCode)
  const [cityArr, setCityArr] = useState([])

  const [addStudentDetails, setAddStudentDetails] = useState({
    name: "",
    gender: "",
    Class: "",
    email: "",
    school: "",
    mobile: "",
    state: "",
    city: "",
    password: "",
    cpassword: "",
  })
  const handleInputs = (e) => {
    const name = e.target.name
    const value = e.target.value
    setAddStudentDetails({ ...addStudentDetails, [name]: value })
  }

  const signupUser = async (e) => {
    e.preventDefault()
    try {
      const {
        name,
        gender,
        Class,
        email,
        school,
        mobile,
        state,
        city,
        password,
        cpassword,
      } = addStudentDetails
      const res = await fetch("/api/admin/dashboard/students", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          name,
          gender,
          Class,
          email,
          school,
          mobile,
          state,
          city,
          password,
          cpassword,
        }),
        credentials: "include",
      })
      const data = await res.json()
      if (!data || res.status === 422) {
        window.alert("UNSUCCESSFULL OPERATION")
      } else {
        window.alert("REGISTERATION SUCCESSFULL !")
        navigate("/dashboard")
      }
    } catch (error) {
      window.alert(`Error: ${error}`)
    }
  }

  const getStudentData = async () => {
    try {
      const res = await fetch("/api/admin/dashboard/students", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        // used when we want to share cookies or tokes to backend
        credentials: "include",
      })

      // console.log(res);
      if (res.status !== 200) {
        throw new Error(res.error)
      }
      const data = await res.json()
      setallstu(data)

      // const imgRes = await fetch(`/image/${data._id}`);
      // const buffer = await imgRes.arrayBuffer();
      // setImage(
      //   `data:${data.img.contentType};base64, ${Buffer.from(buffer).toString(
      //     "base64"
      //   )}`
      // );
      setUserData(data)
      var schools = []
      var classes = []
      var states = []
      var cities = []
      for (let i = 0; i < data.length; i++) {
        if (!schools.includes(data[i]["school"])) {
          schools.push(data[i]["school"])
        }
        if (!classes.includes(data[i]["Class"])) {
          classes.push(data[i]["school"])
        }
        if (!states.includes(data[i]["state"])) {
          states.push(data[i]["state"])
        }
        if (!cities.includes(data[i]["city"])) {
          cities.push(data[i]["city"])
        }
      }
      setClassNames(classes)
      setSchoolNames(schools)
      setStateNames(states)
      setCityNames(cities)

      //console.log(te)
    } catch (error) {
      console.log(error)
      //navigate("/franchise/login");
    }
  }

  const reset_filter = () => {
    setSelectedClass("*")
    setSelectedSchool("*")
    setSelectedState("*")
    setSelectedCity("*")
    setSearchBox("")
  }

  const searchHandler = async () => {
    try {
      let obj = {}
      if (selectedCity !== "*") {
        obj.city = selectedCity
      }
      if (selectedState !== "*") {
        obj.state = selectedState
      }

      if (selectedClass !== "*") {
        obj.Class = selectedClass
      }

      if (selectedSchool !== "*") {
        obj.school = selectedSchool
      }

      obj.searchKey = searchBox
      console.log(obj)
      obj = JSON.stringify(obj)
      //   console.log(obj)
      const res = await fetch("/api/admin/dashboard/students/search", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: obj,
        // used when we want to share cookies or tokes to backend
        credentials: "include",
      })

      if (res.status.toString()[0] !== "2") {
        throw new Error(res.error)
      }
      const data = await res.json()
      console.log("filtered data is -", data)
      setDynamicArray([...data])
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getStudentData()

    // fetch all city and store it
    if (addStudentDetails.state !== "") {
      let stateCode = ""
      for (let i = 0; i < stateArr.length; i++) {
        if (stateArr[i].name === addStudentDetails.state) {
          stateCode = stateArr[i].isoCode
          break
        }
      }
      setCityArr(City.getCitiesOfState(countryCode, String(stateCode)))
    }

    if (
      selectedState !== "*" ||
      selectedCity !== "*" ||
      selectedClass !== "*" ||
      selectedSchool !== "*"
    ) {
      searchHandler()
    }
    console.log(searchBox)
    // console.log(allstu)
  }, [
    addStudentDetails.state,
    selectedClass,
    selectedSchool,
    selectedState,
    selectedCity,
    searchBox,
    // dynamicArr,
  ])
  return (
    <>
      <div className='card m-4'>
        <div className='card-body'>
          <h2 className='my-2'>Students</h2>
          {/* modal trigger button */}
          <button
            type='button'
            class='btn btn-md btn-outline-success my-1'
            data-mdb-toggle='modal'
            data-mdb-target='#addStu'
          >
            Add Student +
          </button>
          {/* modal */}
          <div
            class='modal fade'
            id='addStu'
            tabindex='-1'
            aria-labelledby='exampleModalLabel'
            aria-hidden='true'
          >
            <div class='modal-dialog'>
              <div class='modal-content'>
                <div class='modal-header'>
                  <h5 class='modal-title' id='exampleModalLabel'>
                    Add new Student
                  </h5>
                  <button
                    type='button'
                    class='btn-close'
                    data-mdb-dismiss='modal'
                    aria-label='Close'
                  ></button>
                </div>
                <form action=''>
                  <div
                    class='modal-body'
                    style={{ backgroundColor: "#907a50" }}
                  >
                    <div className='credentials'>
                      <p>
                        Full Name :
                        <br />
                        <input
                          className='input'
                          type='text'
                          name='name'
                          id='name'
                          placeholder='Fill in your Full name'
                          autoComplete='off'
                          value={addStudentDetails.name}
                          onChange={handleInputs}
                        />
                      </p>
                      <p>
                        Gender :
                        <br />
                        <select
                          name='gender'
                          id='gender'
                          className='input'
                          value={addStudentDetails.gender}
                          onChange={handleInputs}
                        >
                          <option value='null' selected hidden>
                            Select your Gender
                          </option>
                          <option value='male'>Male</option>
                          <option value='female'>Female</option>
                          <option value='others'>Others</option>
                        </select>
                      </p>
                      <p>
                        Class :
                        <br />
                        <select
                          name='Class'
                          id='Class'
                          className='input'
                          value={addStudentDetails.Class}
                          onChange={handleInputs}
                        >
                          <option value='null' selected hidden>
                            Select your Class
                          </option>
                          <option value='9'>9</option>
                          <option value='10'>10</option>
                          <option value='11'>11</option>
                          <option value='12'>12</option>
                        </select>
                      </p>
                      <p>
                        Mobile No :
                        <br />
                        <input
                          className='input'
                          type='mobile'
                          name='mobile'
                          id='mobile'
                          placeholder='Fill in your mobile no'
                          autoComplete='off'
                          value={addStudentDetails.mobile}
                          onChange={handleInputs}
                        />
                      </p>
                      <p>
                        Email Address :
                        <br />
                        <input
                          className='input'
                          type='email'
                          name='email'
                          id='email'
                          placeholder='Fill in your email address'
                          autoComplete='off'
                          value={addStudentDetails.email}
                          onChange={handleInputs}
                        />
                      </p>
                      <p>
                        Password :
                        <br />
                        <input
                          className='input'
                          type='password'
                          name='password'
                          id='password'
                          placeholder='Create a password'
                          autoComplete='off'
                          value={addStudentDetails.password}
                          onChange={handleInputs}
                        />
                      </p>
                      <p>
                        Confirm Password :
                        <br />
                        <input
                          className='input'
                          type='password'
                          name='cpassword'
                          id='cpassword'
                          placeholder='Confirm password'
                          autoComplete='off'
                          value={addStudentDetails.cpassword}
                          onChange={handleInputs}
                        />
                      </p>
                      <p>
                        School :
                        <br />
                        <input
                          className='input'
                          type='text'
                          name='school'
                          id='school'
                          placeholder='Input your School Name'
                          autoComplete='off'
                          value={addStudentDetails.school}
                          onChange={handleInputs}
                        />
                      </p>
                      <p>
                        State :
                        <br />
                        <select
                          name='state'
                          id='state'
                          className='input'
                          placeholder='Input your State Name'
                          value={addStudentDetails.state}
                          onChange={handleInputs}
                        >
                          <option value='null' selected hidden>
                            Select your State
                          </option>
                          {stateArr.map((e, i) => (
                            <option value={e.name} key={i}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                      </p>
                      <p>
                        City :
                        <br />
                        <select
                          name='city'
                          id='city'
                          className='input'
                          value={addStudentDetails.city}
                          onChange={handleInputs}
                        >
                          <option value='null' selected hidden>
                            Select your City
                          </option>
                          {cityArr.map((e, i) => (
                            <option value={e.name} key={i}>
                              {e.name}
                            </option>
                          ))}
                        </select>
                      </p>
                    </div>
                    <button
                      type='submit'
                      class='btn btn-primary'
                      onClick={signupUser}
                    >
                      Save student details
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class='filter'>
            <button
              type='button'
              class='btn btn-primary filter-btn'
              onClick={reset_filter}
              style={{ display: "block" }}
            >
              All Students
            </button>
            {/* State Selector */}
            <div class='btn-group filter-btn shadow-0'>
              <button
                type='button'
                class='btn btn-light dropdown-toggle'
                data-mdb-toggle='dropdown'
                aria-expanded='false'
              >
                {selectedState === "" || selectedState === "*"
                  ? "State"
                  : selectedState}
              </button>
              <ul class='dropdown-menu'>
                <li>
                  <a
                    class='dropdown-item'
                    onClick={() => {
                      setSelectedState("*")
                    }}
                  >
                    All State
                  </a>
                </li>
                {stateNames.map((e) => (
                  <li>
                    <a
                      class='dropdown-item'
                      onClick={() => {
                        setSelectedState(e)
                      }}
                    >
                      {e}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* City Selector */}
            <div class='btn-group filter-btn shadow-0'>
              <button
                type='button'
                class='btn btn-light dropdown-toggle'
                data-mdb-toggle='dropdown'
                aria-expanded='false'
              >
                {selectedCity === "" || selectedCity === "*"
                  ? "City"
                  : selectedCity}
              </button>
              <ul class='dropdown-menu'>
                <li>
                  <a
                    class='dropdown-item'
                    onClick={() => {
                      setSelectedCity("*")
                    }}
                  >
                    All City
                  </a>
                </li>
                {cityNames.map((e) => (
                  <li>
                    <a
                      class='dropdown-item'
                      onClick={() => {
                        setSelectedCity(e)
                      }}
                    >
                      {e}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* School Selector */}
            <div class='btn-group filter-btn shadow-0'>
              <button
                type='button'
                class='btn btn-light dropdown-toggle'
                data-mdb-toggle='dropdown'
                aria-expanded='false'
              >
                {selectedSchool === "" || selectedSchool === "*"
                  ? "School"
                  : selectedSchool}
              </button>
              <ul class='dropdown-menu'>
                <li>
                  <a
                    class='dropdown-item'
                    onClick={() => {
                      setSelectedSchool("*")
                    }}
                  >
                    All School
                  </a>
                </li>
                {schoolNames.map((e) => (
                  <li>
                    <a
                      class='dropdown-item'
                      onClick={() => {
                        setSelectedSchool(e)
                      }}
                    >
                      {e}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* Class Selector */}
            <div class='btn-group filter-btn shadow-0'>
              <button
                type='button'
                class='btn btn-light dropdown-toggle'
                data-mdb-toggle='dropdown'
                aria-expanded='false'
              >
                {selectedClass === "" || selectedClass == "*"
                  ? "Class"
                  : selectedClass}
              </button>
              <ul class='dropdown-menu'>
                <li>
                  <a
                    class='dropdown-item'
                    onClick={() => {
                      setSelectedClass("*")
                    }}
                  >
                    All Class
                  </a>
                </li>
                <li>
                  <hr class='dropdown-divider' />
                </li>
                <li>
                  <a
                    class='dropdown-item'
                    onClick={() => {
                      setSelectedClass("9")
                    }}
                  >
                    9
                  </a>
                </li>
                <li>
                  <a
                    class='dropdown-item'
                    onClick={() => {
                      setSelectedClass("10")
                    }}
                  >
                    10
                  </a>
                </li>
                <li>
                  <a
                    class='dropdown-item'
                    onClick={() => {
                      setSelectedClass("11")
                    }}
                  >
                    11
                  </a>
                </li>
                <li>
                  <a
                    class='dropdown-item'
                    onClick={() => {
                      setSelectedClass("12")
                    }}
                  >
                    12
                  </a>
                </li>
              </ul>
            </div>
            {/* Search Box */}
            <div class='input-group'>
              <div
                class='form-outline'
                style={{
                  backgroundColor: "#FBFBFB",
                }}
              >
                <input
                  type='search'
                  id='form1'
                  class='form-control'
                  value={searchBox}
                  onChange={(e) => {
                    setSearchBox(e.target.value)
                  }}
                />
                <label class='form-label' for='form1'>
                  Search
                </label>
              </div>
              <button
                type='button'
                class='btn btn-primary'
                onClick={searchHandler}
              >
                <i class='fas fa-search'></i>
              </button>
            </div>
          </div>

          <div className='tab-content pt-2 pl-1' id='pills-tabContent'>
            <div
              className='tab-pane fade show active'
              id='a-0'
              role='tabpanel'
              aria-labelledby='pills-home-tab'
            >
              <Students
                data={
                  selectedCity === "*" &&
                  selectedClass === "*" &&
                  selectedSchool === "*" &&
                  selectedState === "*" &&
                  searchBox === ""
                    ? allstu
                    : dynamicArr
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
