import React from "react"
import { Link, useNavigate } from "react-router-dom"
import Profile from "./Profile"
import AltPage from "./AltPage"
import Alltests from "./Alltests"
import { useEffect, useState } from "react"
import Calendar from "./Calendar"
import useAuth from "./Hooks/useAuth"
const Buffer = require("Buffer").Buffer

export default function Dashboard() {
  //const navigate = useNavigate();
  const { user, dispatch } = useAuth()

  const [userData, setUserData] = useState({})
  const [image, setImage] = useState(null)

  // fetch userData and userImage
  const callDashboardPage = async () => {
    try {
      const res = await fetch("/api/admin/dashboard", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        // used when we want to share cookies or tokes to backend
        credentials: "include",
      })
      if (!res.ok) {
        throw new Error(res.error)
      }
      const data = await res.json()
      setUserData(data)
      if (data.img && data.img.data) {
        // console.log("Here is your image :", data.img);
        const imgRes = await fetch(`/api/image/${data._id}`)
        const buffer = await imgRes.arrayBuffer()

        if (data.img && data.img.data) {
          const imgRes = await fetch(`/api/image/${data._id}`)
          const buffer = await imgRes.arrayBuffer()

          const uint8Array = new Uint8Array(buffer)
          const base64String = btoa(String.fromCharCode(...uint8Array))
          //   console.log("base64 is ", base64String);
          setImage(`data:${data.img.contentType};base64, ${base64String}`)
        }
      } else {
        console.log("No image data")
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    callDashboardPage()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='App' style={{ height: "100vh" }}>
      <div className='header'>
        <nav
          class='navbar navbar-expand-lg navbar-dark bg-dark'
          style={{ background: "#2B2B2BE8 !important" }}
        >
          <div class='container'>
            <a class='navbar-brand me-2' href='https://mdbgo.com/'>
              <img
                src='../img/images/logo-m.png'
                height='40'
                alt='MDB Logo'
                loading='lazy'
                style={{ marginTop: -"1px" }}
              />
            </a>

            <button
              class='navbar-toggler'
              type='button'
              data-mdb-toggle='collapse'
              data-mdb-target='#navbarButtonsExample'
              aria-controls='navbarButtonsExample'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <i class='fas fa-bars'></i>
            </button>

            <div class='collapse navbar-collapse' id='navbarButtonsExample'>
              <div
                class='d-flex align-items-center'
                style={{
                  marginLeft: "auto",
                }}
              >
                <ul className='navbar-nav mb-2 mb-lg-0'>
                  <li class='nav-item'>
                    <a
                      class='nav-link'
                      href='#'
                      data-mdb-toggle='modal'
                      data-mdb-target='#exampleModal'
                    >
                      <i className='fas fa-info-circle'></i> Help & support
                    </a>

                    {/* Modal */}
                    <div
                      class='modal fade'
                      id='exampleModal'
                      tabindex='-1'
                      aria-labelledby='exampleModalLabel'
                      aria-hidden='true'
                    >
                      <div class='modal-dialog'>
                        <div class='modal-content'>
                          <div class='modal-header'>
                            <h5 class='modal-title' id='exampleModalLabel'>
                              Got questions? Contact us
                            </h5>
                            <button
                              type='button'
                              class='btn-close'
                              data-mdb-dismiss='modal'
                              aria-label='Close'
                            ></button>
                          </div>
                          <div
                            style={{ paddingLeft: "25px" }}
                            class='modal-body'
                          >
                            <h6 style={{ marginBottom: "25px" }}>
                              <span class='key'>
                                <i class='fa-solid fa-phone'></i>&nbsp; Call:
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp; +91 639-450-6912
                            </h6>
                            <h6>
                              <span class='key'>
                                <i class='fa-solid fa-envelope'></i>&nbsp;
                                Email:
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              franchise@careerclarify.com
                            </h6>
                          </div>
                          {/* <form
                            action='https://formspree.io/f/mgebvvyl'
                            method='POST'
                          >
                            <div class='modal-body'>
                              <div class='form-outline mb-4'>
                                <input
                                  type='text'
                                  id='form4Example1'
                                  class='form-control'
                                  name='name'
                                  required
                                />
                                <label class='form-label' for='form4Example1'>
                                  Name
                                </label>
                              </div>

                              <div class='form-outline mb-4'>
                                <input
                                  type='email'
                                  id='form4Example2'
                                  class='form-control'
                                  name='email'
                                  required
                                />
                                <label class='form-label' for='form4Example2'>
                                  Email address
                                </label>
                              </div>

                              <div class='form-outline mb-4'>
                                <textarea
                                  class='form-control'
                                  id='form4Example3'
                                  rows='4'
                                  name='message'
                                  required
                                ></textarea>
                                <label
                                  class='form-label'
                                  for='form4Example3'
                                  name='message'
                                >
                                  Message
                                </label>
                              </div>
                            </div>
                            <div class='modal-footer'>
                              <button
                                type='submit'
                                class='btn btn-info btn-block mb-4'
                              >
                                Send <i className='fa fa-paper-plane'></i>
                              </button>
                            </div>
                          </form> */}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <div class='dropdown'>
                      <a
                        class='nav-link dropdown-toggle d-flex align-items-center hidden-arrow'
                        href='#'
                        id='navbarDropdownMenuAvatar'
                        role='button'
                        data-mdb-toggle='dropdown'
                        aria-expanded='false'
                      >
                        <i className='far fa-user-circle'></i> &nbsp;
                        {userData.name || "User"}
                      </a>
                      <ul
                        class='dropdown-menu dropdown-menu-end'
                        aria-labelledby='navbarDropdownMenuAvatar'
                      >
                        <li>
                          <Link class='dropdown-item' to='/logout'>
                            Log Out
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div className='row mx-0' style={{ height: "100vh" }}>
        {/* sidebar */}
        <div className='col-md-2 p-0' style={{ backgroundColor: "#FDFAE3" }}>
          <div
            className='nav p-3 flex-column nav-pills text-center navbar-dark bg-dark'
            id='v-pills-tab'
            style={{ height: "100%" }}
            role='tablist'
            aria-orientation='vertical'
          >
            <a
              className='nav-link active'
              id='v-pills-home-tab'
              data-mdb-toggle='pill'
              href='#v-1'
              role='tab'
              aria-controls='v-pills-home'
              aria-selected='true'
            >
              Profile <i class='fa-solid fa-user-graduate'></i>
            </a>

            <a
              className='nav-link'
              id='v-pills-profile-tab'
              data-mdb-toggle='pill'
              href='#v-2'
              role='tab'
              aria-controls='v-pills-profile'
              aria-selected='false'
            >
              Dashboard <i class='fas fa-tachometer-alt'></i>
            </a>
            <a
              className='nav-link'
              id='v-pills-messages-tab'
              data-mdb-toggle='pill'
              href='#v-3'
              role='tab'
              aria-controls='v-pills-messages'
              aria-selected='false'
            >
              Calender <i className='fas fa-calendar-alt'></i>
            </a>

            <a
              className='nav-link'
              id='v-pills-messages-tab'
              data-mdb-toggle='pill'
              href='#v-4'
              role='tab'
              aria-controls='v-pills-messages'
              aria-selected='false'
            >
              1:1 Session <i className='fas fa-chalkboard-teacher'></i>
            </a>
          </div>
        </div>

        <div className='col-md-10 pb-5' style={{ background: "#FDFAE3" }}>
          <div className='tab-content' id='v-pills-tabContent'>
            <div
              className='tab-pane fade show active'
              id='v-1'
              role='tabpanel'
              aria-labelledby='v-pills-home-tab'
            >
              {/* {JSON.stringify(userData) === "{}" ? (
                <h2>Loading...</h2>
              ) : ( */}
              <Profile userData={userData} image={image} />
              {/* )} */}
            </div>
            <div
              className='tab-pane fade'
              id='v-2'
              role='tabpanel'
              aria-labelledby='v-pills-profile-tab'
            >
              <Alltests />
            </div>
            <div
              className='tab-pane fade'
              id='v-3'
              role='tabpanel'
              aria-labelledby='v-pills-messages-tab'
            >
              <Calendar />
            </div>
            <div
              className='tab-pane fade'
              id='v-4'
              role='tabpanel'
              aria-labelledby='v-pills-messages-tab'
            >
              <AltPage />
            </div>
            <div
              className='tab-pane fade'
              id='v-5'
              role='tabpanel'
              aria-labelledby='v-pills-messages-tab'
            >
              <AltPage />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
