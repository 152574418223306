import React, { useContext } from "react"
import Login from "./Login/Login"
import Logout from "./Logout/Logout"
import Signup from "./Signup/Signup"
import Dashboard from "./Dashboard"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import Report from "./Report"
import useAuth from "./Hooks/useAuth"

const CareerClarify = () => {
  const { user } = useAuth()
  console.log(user)
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              user ? <Navigate to='/dashboard' /> : <Navigate to='/login' />
            }
          />
          <Route
            path='/dashboard'
            element={user ? <Dashboard /> : <Navigate to='/login' />}
          />
          <Route
            path='/report'
            element={user ? <Report /> : <Navigate to='/login' />}
          />
          <Route
            path='/login'
            element={user ? <Navigate to='/dashboard' /> : <Login />}
          />
          <Route
            path='/signup'
            element={user ? <Navigate to='/dashboard' /> : <Signup />}
          />
          <Route path='/logout' element={<Logout />} />
          <Route path='*' element={<h1>Page Not Found</h1>} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default CareerClarify
