import React from "react"
import { axios } from "axios"
import { useState } from "react"
import useAuth from "./Hooks/useAuth"
export default function EditStu(props) {
  const { user } = useAuth()
  const [name, setname] = useState(props.data.name)
  const [Class, setClass] = useState(props.data.Class)
  const [school, setschool] = useState(props.data.school)
  const [email, setemail] = useState(props.data.email)

  const chk1 = (e) => {
    setname(e.target.value)
  }

  const chk2 = (e) => {
    setClass(e.target.value)
  }

  const chk3 = (e) => {
    setschool(e.target.value)
  }

  const chk4 = (e) => {
    setemail(e.target.value)
  }

  const saveChanges = async (e) => {
    e.preventDefault()
    try {
      //alert(JSON.stringify(props.data));
      const res = await fetch("/api/admin/dashboard/students/update", {
        method: "POST",
        body: JSON.stringify({
          _id: props.data._id,
          name: name,
          Class: Class,
          school: school,
          email: email,
        }),
        headers: {
          Accept: "appllication/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        // used when we want to share cookies or tokes to backend
        credentials: "include",
      })

      // console.log(res);
      if (res.status !== 200) {
        throw new Error(res.error)
      }
      const data = res.json()
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      class='modal fade'
      id={`exampleModal-${props.data._id}`}
      tabindex='-1'
      aria-labelledby='exampleModalLabel'
      aria-hidden='true'
    >
      <div class='modal-dialog'>
        <div class='modal-content'>
          <div class='modal-header'>
            <h5 class='modal-title' id='exampleModalLabel'>
              Edit data
            </h5>
            <button
              type='button'
              class='btn-close'
              data-mdb-dismiss='modal'
              aria-label='Close'
            ></button>
          </div>
          <form action='/' onSubmit={saveChanges}>
            <div class='modal-body'>
              <input
                onChange={chk1}
                type='text'
                name=''
                id={`${props.data._id}-1`}
                className='form-control mb-2'
                placeholder={`Name:${props.data.name}`}
              />
              <input
                onChange={chk2}
                type='text'
                name=''
                id={`${props.data._id}-2`}
                className='form-control mb-2'
                placeholder={`Class:${props.data.Class}`}
              />
              <input
                onChange={chk3}
                type='text'
                name=''
                id={`${props.data._id}-3`}
                className='form-control mb-2'
                placeholder={`School:${props.data.school}`}
              />
              <input
                onChange={chk4}
                type='email'
                name=''
                id={`${props.data._id}-4`}
                className='form-control mb-2'
                placeholder={`Email:${props.data.email}`}
              />
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-secondary'
                data-mdb-dismiss='modal'
              >
                Close
              </button>
              <button type='submit' class='btn btn-primary'>
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
