import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "./Hooks/useAuth"

// instead of taking userData and image from parent just fetch these here so after updation we can show updated data on the fly
export default function Profile({ userData, image }) {
  const { user } = useAuth()
  const [file, setFile] = useState(null)
  const [error, setError] = useState(null)
  const [editable, setEditable] = useState(userData ? false : true)
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    city: "",
    state: "",
  })
  //   console.log(userDetails);

  const handleFileChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleUpload = async (e) => {
    e.preventDefault()
    try {
      if (!file) {
        setError("Please select a file")
        return
      }

      const formData = new FormData()
      formData.append("testImage", file)
      formData.append("userId", userData._id)
      const res = await fetch("/api/admin/dashboard", {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })

      if (!res.ok) {
        throw new Error(res.statusText)
      }
      setFile(null)
      alert("File uploaded successfully!")
    } catch (err) {
      setError(err.message)
    }
  }

  const handleDetailsEdit = async (e) => {
    e.preventDefault()
    try {
      const name =
        userDetails.name.length === 0 ? userData.name : userDetails.name
      const mobile =
        userDetails.mobile.length === 0 ? userData.mobile : userDetails.mobile
      const email =
        userDetails.email.length === 0 ? userData.email : userDetails.email
      const state =
        userDetails.state.length === 0 ? userData.state : userDetails.state
      const city =
        userDetails.city.length === 0 ? userData.city : userDetails.city

      const res = await fetch("/api/admin/dashboard/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          name,
          mobile,
          email,
          state,
          city,
        }),
      })
      const data = await res.json()
      // Debug
      console.log(res)
      if (!data || res.status === 422) {
        window.alert("SOMETHING WENT WRONG! PLEASE CHECK ALL FIELDS !")
      } else {
        window.alert("PROFILE UPDATED!")
        setUserDetails({
          name: "",
          email: "",
          mobile: "",
          city: "",
          state: "",
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <h2 className='text-center mt-5'>My profile</h2>
      <div className='card m-4'>
        <div className='card-body'>
          <div className='row'>
            <div className='col-md-4 text-center'>
              <img
                className='mt-4'
                src={
                  image
                    ? image
                    : "https://mdbootstrap.com/img/Others/documentation/3.webp"
                }
                alt='Profile Pic'
                height={"200px"}
                width={"200px"}
                style={{ borderRadius: "50%" }}
              />
              <form
                onSubmit={handleUpload}
                className='text-center'
                style={{
                  display: "flex",
                  //   flexDirection: "column",
                  alignItems: "end",
                  justifyContent: "center",
                }}
              >
                <input
                  type='file'
                  id='fileUploadButton'
                  accept='image/jpeg, image/png'
                  onChange={handleFileChange}
                  style={{
                    width: "100%",
                    margin: "20px auto",
                    display: "none",
                  }}
                />
                {/* Temperoary upload form */}
                <div
                  type='none'
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "36px ",
                    height: "32px",
                    cursor: "pointer",
                    // borderRadius: "50%",
                    border: "1px solid black",
                    marginTop: "20px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    document.getElementById("fileUploadButton").click()
                  }}
                >
                  <i class='fa-solid fa-user-pen'></i>
                </div>
                <button type='submit' style={{ height: "32px" }}>
                  Upload
                </button>
              </form>
              {error && (
                <p style={{ color: "red" }}>
                  `{error} and please make sure image size is below 100kb
                </p>
              )}
            </div>
            <div className='col-md-8'>
              <div style={{ textAlign: "right", marginBottom: "8px" }}>
                <p>
                  <button
                    style={{ cursor: "pointer", padding: "3px 10px" }}
                    onClick={() => {
                      setEditable(!editable)
                      //   console.log(editable);
                    }}
                  >
                    {editable ? (
                      <i class='fa-solid fa-xmark'></i>
                    ) : (
                      <i class='fa-regular fa-pen-to-square'></i>
                    )}
                    {editable ? ` Close` : ` Edit`}
                  </button>
                </p>
              </div>

              <form>
                <input
                  type='text'
                  id='defaultContactFormName'
                  class='form-control mb-4'
                  placeholder={`Name : ${userData.name || "Your Name"}`}
                  readOnly={editable ? false : true}
                  value={userDetails.name}
                  onChange={(e) => {
                    setUserDetails({ ...userDetails, name: e.target.value })
                  }}
                />

                <input
                  type='email'
                  id='defaultContactFormEmail'
                  class='form-control mb-4'
                  readOnly={editable ? false : true}
                  placeholder={`Email : ${userData.email || "Your E-mail"}`}
                  value={userDetails.email}
                  onChange={(e) => {
                    setUserDetails({ ...userDetails, email: e.target.value })
                  }}
                />

                <input
                  type='text'
                  id='defaultContactFormMobile'
                  class='form-control mb-4'
                  placeholder={`Mobile No. : ${
                    userData.mobile || "Your Mobile No."
                  }`}
                  readOnly={editable ? false : true}
                  value={userDetails.mobile}
                  onChange={(e) => {
                    setUserDetails({ ...userDetails, mobile: e.target.value })
                  }}
                />
                <input
                  type='text'
                  id='defaultContactFormCity'
                  class='form-control mb-4'
                  placeholder={`City : ${userData.city || "Your City"}`}
                  readOnly={editable ? false : true}
                  value={userDetails.city}
                  onChange={(e) => {
                    setUserDetails({ ...userDetails, city: e.target.value })
                  }}
                />
                <input
                  type='text'
                  id='defaultContactFormState'
                  class='form-control mb-4'
                  placeholder={`State : ${userData.state || "Your State"}`}
                  readOnly={editable ? false : true}
                  value={userDetails.state}
                  onChange={(e) => {
                    setUserDetails({ ...userDetails, state: e.target.value })
                  }}
                />

                <button
                  disabled={!editable}
                  class='mt-2 btn btn-info btn-block'
                  type='submit'
                  onClick={handleDetailsEdit}
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
