import React, { useState } from "react"
import "./Signup.css"
import { NavLink, useNavigate } from "react-router-dom"

const Signup = () => {
  const navigate = useNavigate()
  const [userDetails, setUserDetails] = useState({
    name: "",
    gender: "",
    Class: "",
    email: "",
    password: "",
    cpassword: "",
    school: "",
  })
  const handleInputs = (e) => {
    const name = e.target.name
    const value = e.target.value
    // console.log(userDetails);
    setUserDetails({ ...userDetails, [name]: value })
  }

  const signupUser = async (e) => {
    e.preventDefault()
    try {
      const { name, gender, Class, email, password, cpassword, school } =
        userDetails

      const res = await fetch("/api/admin/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          gender,
          Class,
          email,
          password,
          cpassword,
          school,
        }),
      })
      const data = await res.json()
      // Debug
      // console.log(res);
      if (!data || res.status === 422) {
        window.alert("INVALID CREDENTIALS !")
      } else {
        window.alert("REGISTERATION SUCCESSFULL !")
        navigate("/login")
      }
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <section id='signup-section'>
        <h1>Create Account</h1>
        <h3>
          <span>All Fields Are Required </span>
        </h3>
        <form method='POST' className='signup-modal'>
          <div className='credentials'>
            <p>
              Full Name :
              <br />
              <input
                className='input'
                type='text'
                name='name'
                id='name'
                placeholder='Fill in your Full name'
                autoComplete='off'
                value={userDetails.name}
                onChange={handleInputs}
              />
            </p>
            <p>
              Gender :
              <br />
              <input
                className='input'
                type='text'
                name='gender'
                id='gender'
                placeholder='Fill in your Gender'
                autoComplete='off'
                value={userDetails.gender}
                onChange={handleInputs}
              />
            </p>
            <p>
              Class :
              <br />
              <input
                className='input'
                type='text'
                name='Class'
                id='Class'
                placeholder='Fill in the Class in which you are '
                autoComplete='off'
                value={userDetails.Class}
                onChange={handleInputs}
              />
            </p>
            <p>
              Fill in your Email Address :
              <br />
              <input
                className='input'
                type='email'
                name='email'
                id='email'
                placeholder='Fill in your email address'
                autoComplete='off'
                value={userDetails.email}
                onChange={handleInputs}
              />
            </p>
            <p>
              Password :
              <br />
              <input
                className='input'
                type='password'
                name='password'
                id='password'
                placeholder='Input your password'
                autoComplete='off'
                value={userDetails.password}
                onChange={handleInputs}
              />
            </p>
            <p>
              Confirm Your Password :
              <br />
              <input
                className='input'
                type='password'
                name='cpassword'
                id='cpassword'
                placeholder='Input your password'
                autoComplete='off'
                value={userDetails.cpassword}
                onChange={handleInputs}
              />
            </p>
            <p>
              School :
              <br />
              <input
                className='input'
                type='text'
                name='school'
                id='school'
                placeholder='Input your School Name'
                autoComplete='off'
                value={userDetails.school}
                onChange={handleInputs}
              />
            </p>
          </div>
          <input
            className='Signup'
            type='submit'
            value='Register'
            onClick={signupUser}
          />
          <div className='signup-footer'>
            <NavLink to='/login'>
              <p>Already Registered ?</p>
            </NavLink>
          </div>
        </form>
      </section>
    </>
  )
}

export default Signup
