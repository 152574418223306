import logo from "./logo.svg"
import "./App.css"
import { Link } from "react-router-dom"
import Profile from "./Profile"
import AltPage from "./AltPage"
import Alltests from "./Alltests"
import Login from "./Login/Login"
import CareerClarify from "./CareerClarify"
import React from "react"
import { AuthContextProvider } from "./context/AuthContext"

const App = () => {
  return (
    <>
      <AuthContextProvider>
        <CareerClarify />
      </AuthContextProvider>
    </>
  )
}
export default App
